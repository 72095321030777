import React, { useEffect } from "react";
import Layout from "../components/layout";
import { getItemFromStore } from "../helpers/hash";

export default function Thanks() {
  
  useEffect(() => {
    if (window.ttq) {
      // TikTok Pixel Event for Complete Registration or Lead Submission
      window.ttq.track('CompleteRegistration', {
        value: 0,  // Replace with a value if applicable, otherwise set to 0
        currency: 'USD',  // Optional, specify your currency if needed
        user: {
          email: getItemFromStore("form") && getItemFromStore("form").email,  // Get user email if available
          phone: getItemFromStore("form") && getItemFromStore("form").phone  // Get user phone if available
        }
      });
    }
  }, []);

  return (
    <Layout>
      <section className="section bg-gray-50">
        <div className="mx-auto px-4 py-16 pb-24 max-w-lg">
          <h1 className="font-semibold text-2xl md:text-3xl text-center">
            You're all set.
          </h1>
          <p className="text-center text-gray-700">
            Thank you, {getItemFromStore("form") && getItemFromStore("form").Firstname}! We have received your request for an estimate and will be in touch soon. Look out for our emails. We will be contacting you at the following address:
          </p>
          <div>
            <div className="my-6 bg-white s rounded-xl p-4 shadow-lg">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-2 md:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-600">
                    Full name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {getItemFromStore("form") && getItemFromStore("form").Firstname} {getItemFromStore("form") && getItemFromStore("form").Lastname}
                  </dd>
                </div>
                <div className="py-2 md:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-600">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {getItemFromStore("form") && getItemFromStore("form").email}
                  </dd>
                </div>
                <div className="py-2 md:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-600">
                    Phone
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {getItemFromStore("form") && getItemFromStore("form").phone}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
